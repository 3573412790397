@import '~antd/dist/antd.css';

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.logo {
  /* width: 120px; */
  height: 80%;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 5px 5px 5px 15px;
  float: left;
}

.menu-action {
  /* width: 120px; */
  height: 80%;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 5px 5px 5px 15px;
  float: left;
}

.site-card-wrapper {
  padding: 30px;
}
.parent {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.child {
  width: 100%;
  height: 100%;
  background-color: black; /* fallback color */
  background-position: center;
  background-size: cover;
}

.child:hover,
.child:focus {
  transform: scale(1.2);
  transition: all 0.5s;
}

.text-center {
  text-align: center;
}

.icon {
  vertical-align: middle;
  margin-right: 5px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.login-form {
  width: 300px;
}

.main-has-navigation {
  margin-top: 128px;
}

@media (max-width: 620px) {
  .main-has-navigation {
    margin-top: 160px;
  }
}

.main-has-no-navigation {
  margin-top: 64px;
}

.site-page-header-responsive {
  margin-left: 50px;
  margin-right: 50px;
}

@media (max-width: 560px) {
  .site-page-header-responsive {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-space-item {
  width: 100%;
}

.fullpage {
  width: 80%;
  padding: 2em;
}

@media (max-width: 560px) {
  .fullpage {
    width: 100%;
    padding: 1em;
  }
}

.danger {
  color: red;
}

.danger:hover {
  color: darkred;
}

.tag-inventory {
  width: 300px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
  padding: 10px;
}

.tags-inventory .tag-inventory:hover {
  background: #fafafa;
}

.fill-remaining-space {
  flex: auto;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-cascader-menus {
  overflow: scroll;
  max-width: 95vw;
}

.ant-message {
  z-index: 99999;
}

.ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  height: 130px;
}

.recapture-card > .ant-card-body {
  height: 200px;
}

.text-muted {
  color: #595959;
}

.ant-card-cover .avatar-uploader .ant-upload {
  width: 160px;
  height: 160px;
}

.ant-modal-root {
  z-index: 999999;
  position: absolute;
}


.mapboxgl-map {
  width: 100%;
  height: 70vh;
}

.cluster-marker  {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #51D5A0;
  display: flex;
  justify-content:center;
  align-items: center;
  color: white;
  border: 2px solid #56C498;
  cursor: pointer;
}

.map-marker  {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: "2px solid #C9C9C";
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
  }